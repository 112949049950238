<template>
    <div class="manage-container">
        <!-- <div class="navigation-tabs">
            <div class="nav-links">
                <router-link id="active" to="/Manage" @click="selectTab(0)" :class="{ active: selected[0] }">
                    <font-awesome-icon :icon="['fas', 'gear']" class="nav-icon" />
                    <span>{{ $t("Manage") }}</span>
                </router-link>
                <router-link style="cursor: not-allowed; color: rgb(187, 185, 185)" to="" :class="{ active: selected[1] }">
                    <font-awesome-icon :icon="['fas', 'user']" style="width: 15px; color: rgb(187, 185, 185)" class="nav-icon" />
                    <span>{{ $t("Profile") }}</span>
                </router-link>

                <router-link style="cursor: not-allowed; color: rgb(187, 185, 185)" to="" :class="{ active: selected[2] }">
                    <font-awesome-icon :icon="['fas', 'wrench']" style="width: 17px; color: rgb(187, 185, 185)" class="nav-icon" />
                    <span>{{ $t("Settings") }}</span>
                </router-link>
            </div>
            
        </div> -->
        <div class="grid-row-widgets">
            <div class="widget background-2">
                <div>
                    <span class="widget-title">{{ $t("Applicant dropout") }} </span>
                    <span class="widget-info">28</span>
                </div>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/Chart_100.svg" alt="bar-chart-icon" />
            </div>
            <div class="widget">
                <div>
                    <span class="widget-title">{{ $t("spent this month") }}</span>

                    <span class="widget-info">$682.5</span>
                </div>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-graph.svg" alt="bar-graph" />
            </div>

            <div class="widget">
                <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-chart-icon.svg" alt="bar-chart-icon" />
                <div style="width: 70%">
                    <span class="widget-title">{{ $t("Applicant revival") }}</span>
                    <span class="widget-info">28</span>
                </div>
            </div>

            <div class="widget background-1">
                <div>
                    <span class="widget-title">{{ $t("AVG spent per hire") }}</span>
                    <span class="widget-info">$13.00</span>
                </div>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/Chart_100.svg" alt="bar-chart-icon" />
            </div>
        </div>

        <div class="score-applicants">
            <div>
                <TotalSpent />
            </div>

            <div class="calendar">
                <div class="widget" style="padding: 1%; padding-top: 0">
                    <div>
                        <span class="widget-title">{{ $t("Applicant dropout") }}</span>
                        <span class="widget-info">28</span>
                    </div>

                    <div>
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/Avatar.svg" alt="" />
                    </div>
                </div>
                <div style="width: 350px">
                    <HistogramVue :histogramData="histogramData" :chartWidth="320" :chartHeight="350" :showLabel="false" />
                </div>
                <!-- 
        <button class="viewbtn">
          View All<img loading="lazy"  decoding="async" src="../assets/Images/icons/Vector.svg" alt="" />
        </button> -->
            </div>
        </div>

        <div class="calendar-candidate">
            <div class="applicants">
                <div class="widget background-1">
                    <div>
                        <span class="widget-title">{{ $t("Applicant revival") }}</span>
                        <span class="widget-info">28</span>
                    </div>

                    <div>
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/Graph.svg" alt="" />
                    </div>
                </div>

                <div style="margin-top: 5%">
                    <span class="recentapp">{{ $t("Recent") }}</span>
                    <!-- <button class="viewbtn" style="margin-left: 22%">
            View All
            <img loading="lazy"  decoding="async" src="../assets/Images/icons/Vector.svg" alt="" />
          </button> -->
                </div>

                <div class="recentcandidates">
                    <RacentCandidateRow2 v-for="i in 3" :key="i" />
                </div>
            </div>

            <div class="score">
                <div class="score-head">
                    <div>
                        <span class="on-track">$13.5</span>
                        <h3>{{ $t("AVG spent per hire") }}</h3>
                    </div>
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-chart-icon.svg" alt="" />
                </div>

                <LineChart :graphData="this.graphData" :chartWidth="700" :chartHeight="300" />
            </div>
        </div>
    </div>
</template>

<script>
import RacentCandidateRow2 from "../components/RacentCandidateRow copy.vue";
import TotalSpent from "../components/TotalSpent.vue";
import LineChart from "../charts/Graph.vue";
import HistogramVue from "../charts/Histogram.vue";
export default {
    name: "MangeView",
    components: {
        RacentCandidateRow2,
        TotalSpent,
        LineChart,
        HistogramVue,
    },
    data() {
        return {
            graphData: [
                // Provide your dataset here
                { Date: new Date("2023-01-01"), Close: 20 },
                { Date: new Date("2023-01-02"), Close: 60 },
                { Date: new Date("2023-01-03"), Close: 30 },
                { Date: new Date("2023-01-04"), Close: 50 },
            ],
            histogramData: [
                { label: "january", value: 400 },
                { label: "February", value: 350 },
                { label: "March", value: 53 },
                { label: "April", value: 200 },
                { label: "July", value: 45 },
                { label: "Aout", value: 310 },
                { label: "September", value: 500 },
                { label: "October", value: 200 },
                { label: "November", value: 35 },
                { label: "December", value: 42 },
            ],
            selected: [true, false, false],
            current: 0,
        };
    },
    methods: {
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
    },
};
</script>

<style scoped lang="scss">
.manage-container {
    display: flex;
    flex-direction: column;
}

.grid-row-widgets {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    margin-top: 40px;
}

.widget {
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
}

.widget-info {
    text-align: left;
}

.widget-title {
    text-align: left;
    font-size: 140px;
}

.background-1 {
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
}

.background-1 > div > .widget-info {
    color: #fff;
}

.background-1 > div > .widget-title {
    color: #e9edf7;
}

.background-2 {
    background: linear-gradient(135deg, #fab2ff 0%, #2196f3 100%);
}

.background-2 > div > .widget-info {
    color: #fff;
}

.background-2 > div > .widget-title {
    color: #e9edf7;
}

.widget-title {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: #a3aed0;
    margin-bottom: 8px;
}

.widget-info {
    display: block;
    font-weight: 700;
    font-size: 34px;
    color: #1b2559;
}

.note {
    display: block;
    width: fit-content;
    margin-left: auto;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    border-radius: 7px;
    padding: 4px;
}

.indicator {
    display: grid;
    width: fit-content;
    height: fit-content;
    text-align: center;
    border-radius: 20px;
    padding: 4px;
    align-self: center;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
}

.green {
    background: rgba(5, 205, 153, 0.1);
    color: #05cd99;
}

.red {
    background: rgba(227, 26, 26, 0.1);
    color: #e31a1a;
}

.good {
    background: #05cd99;
}

.average {
    background: #ffd133;
}

.score-applicants {
    //   width: 700px;
    display: grid;
    grid-template-columns: 65% 33%;
    padding: 36px 0px 0 0;
    gap: 20px;

    > :first-child {
        background-color: #fff;
        width: 100%;
        border-radius: 20px;
    }
}

.score {
    display: grid;
    height: 420px;
    width: 100%;
    align-items: center;
    grid-template-rows: 1fr 2fr 3fr;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 16px 0 16px;
}

.applicants {
    background-color: #fff;
    border-radius: 20px;
    padding: 16px;
    display: flow-root;
    text-align: left;
}

.stat-widget {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding: 0px 48px;
}

.stat-widget > div {
    box-shadow: 0px 18px 40px rgb(112 144 176 / 12%);
    padding: 8px 16px;
    display: grid;
    grid-template-rows: 1fr 2fr;
    align-items: center;
    background: #fff;
    border-radius: 20px;
}

.stat-widget > div > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.avg {
    display: flow-root;
}

.calendar-candidate {
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 20px;
    padding: 36px 0px;
}

.calendar-candidate > button {
    border-radius: 24px;
    color: #fff;
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
    right: -215%;
    width: 90%;
    padding: 10%;
    position: relative;
    margin-top: 15%;
    margin-bottom: 30%;
}

.calendar {
    height: 350px;
    display: flow-root;
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
    padding-top: 5%;

    img {
        width: 33px;
        height: 33px;
    }
}

.calendar > button {
    margin: 3% 0% 3% 63%;
}

.candidate {
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
}

.candidate > button {
    margin-top: 2%;
    margin-left: 83%;
}

.calendar-candidate > button:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
    text-decoration: none !important;
}

.score-head {
    display: grid;
    justify-items: baseline;
    align-items: center;
    grid-template-columns: 5fr 2fr;
    margin: 3% 0;

    & > :first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        h3 {
            color: #a3aed0;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.score-head > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: baseline;
    align-items: center;
}

.score-head > div > span {
    color: #1b2559;
}

.score-head > select {
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
}

.score-head > select:focus {
    border: none;
    outline: none;
}

.score-head > img {
    justify-self: end;
    height: 33px;
    height: 33px;
}

.recentapp {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #a3aed0;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
}

.viewbtn:hover {
    text-decoration: underline #2196f3;
}

.recentcandidates {
    display: grid;
    padding: 5% 0%;
    gap: 10px;
}

.tasks {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;
    display: grid;
    background-color: #fff;
    border-radius: 20px;
    margin: 10% 10% 10% 2%;
    padding: 0% 3%;
}

.tasks > div {
    border-left: 3px solid #2196f3;
    display: grid;
    gap: 3px;
    justify-items: left;
    padding-left: 3%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.thedate {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    /* identical to box height, or 124% */

    letter-spacing: -0.02em;
}

.time-stamp {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #a3aed0;
}

.thetask {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1b2559;
}

.tasks > div:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
}

.on-track {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 34px;
    color: #1b2559;
}

/*----------------------------------------------------------------*/

.navigation-tabs {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;
    border-bottom: 2px solid #edeff2;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:first-child:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}
</style>
