<template>
    <div class="">
        <InviteTeammate :showEmail="showEmail" :toggleEmail="toggleEmail" />
        <PostProject :isOpen="isOpen" :togglePostJob="togglePostJob" />

        <div class="assessments-section flex flex-col gap-9 rounded p-3 bg-[#fff]">
            <div class="w-full flex items-start justify-between">
                <div>
                    <div class="flex flex-row items-center justify-start">
                        <h1 class="title">{{ $t("My Projects") }}</h1>
                        <Popper :content="$t('invite team members')" placement="bottom" :hover="true">
                            <button id="targetElement1" ref="targetElement1" class="rounded-full text-lg text-[#7D8FB3]" @click="toggleEmail">
                                <font-awesome-icon :icon="['fas', 'user-plus']" />
                            </button>
                        </Popper>
                    </div>
                </div>

                <div
                    id="targetElement"
                    ref="targetElement"
                    @click="
                        () => {
                            this.$router.push('/newAssessment');
                        }
                    "
                    class="bg-[#2196f3] text-white hover:bg-[#1976d2] inline-flex shadow items-center justify-center text-m rounded min-h-[30px] px-4 py-[10px] font-semibold flex items-center justify-center relative cursor-pointer"
                >
                    New project
                </div>
            </div>
            <div v-if="isLoading" class="loader">
                <LoadingComponent />
            </div>
            <div v-else>
                <div v-if="projects.length > 0" class="projects">
                    <!-- <AssessmentCard
                    v-for="(assessment, index) in this.assessments"
                    :key="index"
                    :assessment="assessment"
                    :addAssessment="addAssessment"
                /> -->
                    <ProjectsTable :projects="this.projects" />
                    <!-- <ProjectsCard v-for="(project, index) in this.projects" :key="index" :project="project" /> -->
                </div>
                <div v-else class="noProjects w-full flex flex-col text-center items-center justify-start">
                    <h1>
                        {{ $t("You currently have no Project, you can create one above.") }}
                    </h1>
                    <img loading="lazy" decoding="async" src="@/assets/empty_state.svg" alt="GO PLATFORM" class="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import AssessmentCard from "@/components/AssessmentCard.vue";
// import InviteCandidate from "@/components/InviteCandidate.vue";
// import LibraryCard from "@/components/LibraryCard.vue";
// import ProjectsCard from "@/components/ProjectsCard.vue";
import ProjectsTable from "@/components/ProjectsTable.vue";
import PostProject from "../components/PostProject.vue";
import InviteTeammate from "@/components/InviteTeammate.vue";
import { useStore } from "../store/index";
// import RacentCandidateRow from "@/components/RacentCandidateRow.vue";
// import axios from "axios";
// import assessement from "@/store/modules/assessement";
import LoadingComponent from "../components/LoadingComponent.vue";
import introJs from "intro.js";
import "intro.js/introjs.css"; // Import Intro.js CSS
import axios from "axios";

export default {
    name: "PartialLibraryView",
    components: {
        // AssessmentCard
        // ProjectsCard,
        ProjectsTable,
        PostProject,
        InviteTeammate,
        LoadingComponent,
    },
    data() {
        return {
            // projects: [],
            isLoading: true,
            showEmail: false,
            isOpen: false,
            t: "",
            imagePath: require(`../assets/onBoardingGIFs/newProject.gif`),
            imagePath1: require(`../assets/onBoardingGIFs/inviteCoworker.gif`),
            selectedAssessments: [],

            options: [
                {
                    img: "adobe-premiere.svg",
                    text: "Adobe Premiere",
                },
                {
                    img: "logos_aws.svg",
                    text: "Amazon web services",
                },
                {
                    img: "google-cloud.svg",
                    text: "Google Cloud",
                },
                {
                    img: "kpmg.svg",
                    text: "KPMG",
                },
                {
                    img: "PWC.svg",
                    text: "PWC",
                },
            ],
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    computed: {
        projects() {
            return this.Store.projects;
        },
    },
    methods: {
        togglePostJob() {
            this.isOpen = !this.isOpen;
        },
        toggleEmail() {
            this.showEmail = !this.showEmail;
        },
        deleteAssessement(assess) {
            const newArr = this.selectedAssessments.filter((el) => el !== assess);
            this.selectedAssessments = newArr;
        },
        addAssessment(test) {
            if (this.selectedAssessments.length < 5) {
                let assessmentFound = this.selectedAssessments.find((el) => el.name === test.name);
                if (assessmentFound) {
                    alert("You already added this assessment");
                } else {
                    let clonedTest = { ...test };
                    clonedTest.delete = true;
                    this.selectedAssessments.push(clonedTest);
                }
                this.showPreview = !this.showPreview;
            } else {
                alert("You can add up to 5 assessments only");
            }
        },

        startIntro() {
            // Initialize Intro.js
            const intro = introJs();

            // Set Intro.js options
            intro.setOptions({
                steps: [
                    {
                        element: this.$refs.targetElement,
                        // <img src="${this.imagePath}" alt="Image Description">
                        intro: `<h3>New Project</h3>
                        <p> The objective is to provide clear, concise, and context-specific guidance directly within the user interface,</p>`,
                        position: "bottom",
                    },
                    {
                        element: this.$refs.targetElement1,
                        // <img src="${this.imagePath1}" alt="Image Description">
                        intro: `<h3>Invite Co-worker</h3>
                        <p>Here you can find how to invite co-worker to the project </p>`,
                        position: "bottom",
                    },

                    // Add more steps as needed
                ],
            });

            // Start the introduction
            intro.start();
        },
        addEventListeners() {
            const elements = document.querySelectorAll(".introjs-overlay");
            elements.forEach((element) => {
                element.addEventListener("click", this.handleClick);
            });
        },
        handleClick() {
            this.$router.push({ path: "/dashboard" });
        },
    },
    async mounted() {
        this.addEventListeners();
        const headers = {
            "Content-Type": "application/json",
            withCredentials: true,
        };
        await this.Store.fetchProjects().then(() => {
            this.isLoading = false;
        });

        // this.startIntro();
        this.t = this.$route.query.t;
        if (this.t == "0") {
            //alert(this.$route.fullPath);
            // Initialize Intro.js
            const intro = introJs();

            // Set Intro.js options
            intro.setOptions({
                steps: [
                    {
                        element: this.$refs.targetElement,
                        // <img src="${this.imagePath}" alt="Image Description">
                        intro: `<h3>New Project</h3>
                        <p> The objective is to provide clear, concise, and context-specific guidance directly within the user interface,</p>`,
                        position: "bottom",
                    },

                    // Add more steps as needed
                ],
            });

            // Start the introduction
            intro.start();
        } else {
            if (this.t == "3") {
                // Initialize Intro.js
                const intro = introJs();

                // Set Intro.js options
                intro.setOptions({
                    steps: [
                        {
                            element: this.$refs.targetElement1,
                            // <img src="${this.imagePath1}" alt="Image Description">
                            intro: `<h3>Invite Co-worker</h3>
                            <p>Here you can find how to invite co-worker to the project </p>`,
                            position: "bottom",
                        },

                        // Add more steps as needed
                    ],
                });

                // Start the introduction
                intro.start();
            }
        }

        await axios
            //https://server.go-platform.com/
            .get(`https://server.go-platform.com/tooltip/get`, {
                headers,
                withCredentials: true,
            })
            .then((res) => {
                //alert(res.data);
                if (res.data == 0) {
                    this.startIntro();
                    axios.get(`https://server.go-platform.com/tooltip/post`, {
                        headers,
                        withCredentials: true,
                    });
                }
            });

        //this.startIntro();
    },
};
</script>

<style scoped lang="scss">
.title-section {
    display: flex;
    align-items: center;
}

.startAssessing {
    padding: 0 2rem;
    color: var(--Secondary-Grey-900, #0f172a);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 42px;
    /* 131.25% */
    letter-spacing: -0.64px;
}

.searchbar {
    width: 30%;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 9px;
    color: #adb8cc;
}

.newProj {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 140px;
    height: 40px;
    background-color: #2196f3;
    border-radius: 10px;
    padding: 0 2%;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        opacity: 0.85;
    }

    /* background: rgb(123, 42, 255);
    background: linear-gradient(90deg, rgba(123, 42, 255, 1) 0%, rgba(0, 174, 240, 1) 100%);*/
}

.cards {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
}

.searchbar-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    /*gap: 48px;*/
    align-items: center;
    padding: 32px 0;

    .add-assessment-button {
        width: 30%;

        background: #2196f3;
        flex-grow: 1;
        border: none;
        border-radius: 70px;
        padding: 1rem 1rem;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        box-shadow: 0 0 2px 0.5px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
            transform: scale(1.04);
        }

        &:disabled {
            background: #e5e5ef;
            color: #656669;
            box-shadow: none;
            cursor: not-allowed;

            &:hover {
                transform: scale(1);
            }
        }

        // box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    }

    > :first-child {
        display: flex;
        gap: 30rem;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;

        .clear {
            background: #fff;
            color: #656669;
            // box-shadow: 0 0 2px 0.5px rgba(0, 0, 0, 0.04);
        }
    }
}

.assessments-section {
    height: 100%;
    margin: 2% 0;

    .title {
        color: #1b2559;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 160% */
        letter-spacing: -0.4px;
        width: 90%;
        margin-right: 20px;

        &::after {
            content: "|";
            height: 40px;
            width: 2px;
            color: #656669;
            margin-left: 20px;
            opacity: 0.2;
        }
    }

    .loader {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.projects {
    // padding: 24px;
    // display: grid;
    // grid-template-columns: 30% 30% 30%;
    align-items: flex-start;

    border-radius: 10px;
    // gap: 3%;
    // grid-row-gap: 10%;
    // margin-bottom: 3%;
}

.validate-button {
    background: #2196f3;
    border: none;
    border-radius: 70px;
    padding: 12px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    width: 100%;
    margin-bottom: 5px;
}

.success-score {
    padding-left: 48px;
}

.slider {
    display: flex;
    align-items: center;
}

.success-score-input {
    background: #fff;
    border: 1px solid #e5e5ef;
    border-radius: 12px;
    padding: 8px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
}

#success-slider {
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(90deg, #2196f3 60%, #fff 60%);
    border: 1px solid #2196f3;
    border-radius: 90px;
    margin-right: 48px;
    width: 35vw;
    height: 16px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #2196f3;
    cursor: pointer;
}

#success-score {
    border: none;
    margin: 0;
    font-family: "Roboto";
    font-weight: 500;
    width: 56px;
    font-size: 24px;
    text-align: center;
    color: #000000;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.grid-row-1 {
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 16px;
    padding: 24px;
}

.grid-row-1 > div > h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 24px;
}

.test-compisition {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 20px;
}

.top-section {
    display: flex;
    justify-content: space-between;
}

.top-section > .text {
    display: flex;
    flex-direction: column;
}

.top-section > .text > * {
    margin-bottom: 24px;
}

.top-section > .text > h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 24px;
}

.on-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: #05cd99;
}

.chart {
    margin: 16px 8px;
}

.recent-candidats {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 20px;
}

.noProjects {
    h1 {
        color: #0f172a;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;
        /* 175% */
        letter-spacing: -0.48px;
        width: 90%;
        margin: 3%;
    }
}

.introjs-tooltip {
    min-width: 200px;
    max-width: 200px;
}
</style>
