<template>
    <div class="payments-container">
        <!-- <div class="navigation-tabs"> -->
        <!-- <div class="nav-links w-full">
               
                <router-link to="/payement" @click="selectTab(0)" :class="{ active: selected[0] }">
                    <font-awesome-icon :icon="['far', 'credit-card']" class="nav-icon" />
                    <span>Plans</span>
                </router-link>
                <router-link to="/invoice" @click="selectTab(1)" :class="{ active: selected[1] }">
                    <font-awesome-icon :icon="['fas', 'chart-bar']" class="nav-icon" />
                    <span>Invoice</span>
                </router-link>
            </div> -->
        <!---- <div class="searchbar">
                <img loading="lazy"  decoding="async" src="../assets/Images/icons/search-icon.svg" alt="search-icon" />
                <input type="text" placeholder="Search Tasks" />
            </div>-->
        <!-- </div> -->
        <PayementControl :show="show" :toggleModal="toggleModal" />
        <div class="grid-row-1">
            <div class="update-credit-card">
                <div>
                    <h1>Update your Credit Card</h1>
                    <p>Update your card for workflow with no hidden fees. Hold and spend what you can.</p>
                    <button>Update</button>
                </div>
                <div class="credit-card">
                    <img loading="lazy" decoding="async" src="../assets/Images/credit-card.jpg" alt="credit-card" />
                </div>
            </div>
            <div class="control-credit-card">
                <img loading="lazy" decoding="async" src="../assets/Images/icons/fingerprint.svg" alt="finger-print" />
                <h1>Control card spending in-app with a tap</h1>
                <p>Control your card, with one tap.</p>
                <p>Spend what you can.</p>
                <button class="strech" @click="toggleModal">Cards</button>
            </div>
        </div>
        <div class="flex-row">
            <div class="plans-header">
                <h1 class="plans-and-pricing">Plans & Pricing</h1>
                <p class="plans-text">Whether your time-saving automation needs are large or small, we're here to help you scale.</p>
            </div>
            <div v-if="activeMonthly" class="plans-switch">
                <button @click="setActiveMonthly" class="plans-button active">MONTHLY</button>
                <button @click="setActiveYearly" class="plans-button">YEARLY<span class="discount">-25%</span></button>
            </div>
            <div v-if="activeYearly" class="plans-switch">
                <button @click="setActiveMonthly" class="plans-button">MONTHLY</button>
                <button @click="setActiveYearly" class="plans-button active">YEARLY<span class="discount discount-active">-25%</span></button>
            </div>
        </div>
        <div class="payement-plans">
            <div class="plan">
                <div>
                    <span class="price">$199</span>
                    <span class="per-month">/month</span>
                </div>
                <div>
                    <span class="plan-title">Starter</span>
                    <span class="plan-text">Unleash the power of automation.</span>
                </div>
                <div class="advantages">
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                </div>
                <button class="choose-button">Choose plan</button>
            </div>
            <div class="plan">
                <div>
                    <span class="price">$199</span>
                    <span class="per-month">/month</span>
                </div>
                <div>
                    <span class="plan-title">Starter</span>
                    <span class="plan-text">Unleash the power of automation.</span>
                </div>
                <div class="advantages">
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                </div>
                <button class="choose-button">Choose plan</button>
            </div>
            <div class="plan">
                <div>
                    <span class="price">$199</span>
                    <span class="per-month">/month</span>
                </div>
                <div>
                    <span class="plan-title">Starter</span>
                    <span class="plan-text">Unleash the power of automation.</span>
                </div>
                <div class="advantages">
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                    <div class="advantage">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/check-circle.svg" alt="check-circle" />
                        <span>20 Assessment Test</span>
                    </div>
                </div>
                <button class="choose-button">Choose plan</button>
            </div>
        </div>
    </div>
</template>

<script>
import PayementControl from "../components/PayementControl.vue";
// import NavTab from "@/components/NavTab.vue";
// import NavTabscontainer from "@/components/NavTabscontainer.vue";
export default {
    name: "PayementView",
    components: {
        PayementControl,
        // NavTab, NavTabscontainer
    },
    data() {
        return {
            show: false,
            activeMonthly: true,
            activeYearly: false,
            selected: [true, false],
            current: 0,
        };
    },
    methods: {
        toggleModal: function () {
            this.show = !this.show;
        },
        setActiveMonthly: function () {
            this.activeMonthly = true;
            this.activeYearly = false;
        },
        setActiveYearly: function () {
            this.activeYearly = true;
            this.activeMonthly = false;
        },
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
    },
};
</script>

<style scoped lang="scss">
button:hover {
    opacity: 0.85;
}

.payments-container {
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.grid-row-1 {
    display: grid;
    grid-template-columns: 6fr 4fr;
    gap: 16px;
    padding: 24px;
}

.grid-row-1 > div {
    background: #fff;
    padding: 16px;
    border-radius: 20px;
}

.update-credit-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    align-items: center;
}

.update-credit-card > div > h1 {
    font-weight: 700;
    font-size: 34px;
    color: #1b2559;
}

.update-credit-card > div > p {
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #a3aed0;
}

.credit-card {
    margin: -16px;
}

.credit-card > img {
    width: 100%;
}

.control-credit-card {
    margin-right: 48px;
}

.control-credit-card > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #1b2559;
}

.control-credit-card > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #a3aed0;
}

.strech {
    display: block;
    width: 100%;
    margin-top: 24px;
}

.flex-row {
    display: flex;
    align-items: center;
}

.plans-header {
    padding: 0px 48px;
    width: 50%;
}

.plans-and-pricing {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #2196f3;
}

.plans-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #848199;
}

.plans-switch {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 22px;
}

.plans-button {
    all: unset;
    padding: 8px 16px;
    color: #3b3472;
    font-weight: 500;
    font-size: 12px;
}

.flex-row .active {
    background: #2196f3;
    box-shadow: 0px 5px 7px rgba(82, 67, 194, 0.230196);
    border-radius: 22px;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
}

.discount {
    color: rgba(243, 10, 10, 0.8);
    font-weight: 500;
    font-size: 12px;
    margin-left: 5px;
}

.discount-active {
    color: #fff;
}

.payement-plans {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 26px;
    margin: 64px;
    //   overflow: hidden;
    gap: 20px;
}

.plan {
    padding: 48px;
    background: #fff;
    border-radius: 26px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    img {
        border-radius: 50%;
        // background-color: #c293fe;
    }

    &:hover {
        color: white;
        background: linear-gradient(340.25deg, #2196f3 2.62%, #fab2ff 97.66%);
        box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755);
        border-radius: 26px;
        transform: scale(1.05);
        z-index: 9 !important;

        button {
            color: #fff;
            background: #c293fe;
            opacity: 1;
        }

        span {
            color: #fff;
        }

        img {
            background-color: #ffffff;
        }
    }
}

.price {
    font-weight: 700;
    font-size: 36px;
    color: #231d4f;
}

.per-month {
    font-weight: 500;
    font-size: 17px;
    color: #848199;
}

.plan-title {
    display: block;
    font-weight: 500;
    font-size: 28px;
    color: #231d4f;
}

.plan-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #848199;
    display: block;
    margin-bottom: 24px;
}

button {
    background: #2196f3;
    border-radius: 70px;
    padding: 12px 56px;
    border: none;
    color: #fff;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.advantage {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.advantage > img {
    margin-right: 8px;
}

.advantage > span {
    font-weight: 500;
    font-size: 15px;
    color: #848199;
}

.choose-button {
    all: unset;
    padding: 8px 24px;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    background: #231d4f;
    opacity: 0.5;
    border-radius: 24px;
}

#active {
    background: linear-gradient(340.25deg, #2196f3 2.62%, #fab2ff 97.66%);
    box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755);
    border-radius: 26px;
    transform: scale(1.2);
    z-index: 9 !important;
}

#active button {
    color: #fff;
    background: #c293fe;
    opacity: 1;
}

#active * {
    color: #fff !important;
}

#active > * {
    transform: scale(0.83);
}

.navigation-tabs {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;
    border-bottom: 2px solid #edeff2;
}

.nav-links > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0px;
    padding-bottom: 24px;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #7d8fb3;
    margin-right: 24px;
    position: relative;
}

.nav-links .nav-icon {
    font-size: 20px;
}

.nav-links img {
    margin-right: 12px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 11px;
    color: #adb8cc;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.nav-links a::after {
    content: "";
    background: #7d8fb3;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.4s ease-in-out;
    border-radius: 25px;
}

.nav-links > a:hover::after {
    width: 100%;
}

.nav-links a.active::after {
    background: #2196f3;
    width: 100%;
}

.nav-links a.active * {
    color: #2196f3;
}
</style>
