<template>
    <div>
        <section class="register">
            <div class="header_text" v-show="step == 1">
                <span> Where should we send the order? </span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
            </div>
            <div class="header_text" v-show="step == 2">
                <span> How would you like to pay? </span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
            </div>
            <div class="header_text" v-show="step == 3">
                <span> Confirm, and start immediately! </span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
            </div>
            <div class="header_text" v-show="step == 4">
                <span> Congratulations! Your Payement is confirmed </span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
            </div>
            <div class="register-stepper">
                <div
                    class="step"
                    :class="{
                        'step-active': step === 1,
                        'step-done': step > 1,
                    }"
                >
                    <span
                        class="step-number"
                        :class="{
                            'step-number-active': step === 1,
                            'step-number-done': step > 1,
                        }"
                    >
                    </span>
                    <span class="step-text" :class="{ 'step-text-active': step > 0 }"> Welcome</span>
                </div>
                <div
                    class="step"
                    :class="{
                        'step-active': step === 2,
                        'step-done': step > 2,
                    }"
                >
                    <span
                        class="step-number"
                        :class="{
                            'step-number-active': step === 2,
                            'step-number-done': step > 2,
                        }"
                    >
                    </span>
                    <span class="step-text" :class="{ 'step-text-active': step > 1 }">Payement</span>
                </div>
                <div
                    class="step"
                    :class="{
                        'step-active': step === 3,
                        'step-done': step > 3,
                    }"
                >
                    <span
                        class="step-number"
                        :class="{
                            'step-number-active': step === 3,
                            'step-number-done': step > 3,
                        }"
                    >
                    </span>
                    <span class="step-text" :class="{ 'step-text-active': step > 2 }">Checkout</span>
                </div>
                <div
                    class="step"
                    :class="{
                        'step-active': step === 4,
                        'step-done': step > 4,
                    }"
                >
                    <span
                        class="step-number"
                        :class="{
                            'step-number-active': step === 4,
                            'step-number-done': step > 4,
                        }"
                    >
                    </span>
                    <span class="step-text" :class="{ 'step-text-active': step > 3 }">Success</span>
                </div>
            </div>
            <Transition name="slide-fade">
                <section v-show="step === 1">
                    <span class="page_title"> Billing Adress</span>
                    <form action="#" class="form" method="post" @submit.prevent="next">
                        <div class="form-group">
                            <div class="form_field">
                                <label for="firstname">First Name</label>
                                <input type="text" id="firstname" v-model="customer.firstName" autocomplete="customer.firstName" required />
                            </div>
                            <div class="form_field">
                                <label for="lastname">Last name</label>
                                <input type="text" id="lastname" v-model="customer.lastName" autocomplete="customer.lastName" required />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form_field">
                                <label for="email"> Email </label>
                                <input type="email" v-model="customer.email" autocomplete="customer.email" id="email" required />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form_field">
                                <label for="adress">Street adress</label>
                                <input type="text" v-model="customer.address" autocomplete="customer.address" id="adress" required />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form_field">
                                <label for="state">State/Province</label>
                                <input type="text" v-model="customer.state" autocomplete="customer.state" id="state" required />
                            </div>
                            <div class="form_field">
                                <label for="city">City</label>
                                <input type="text" v-model="customer.city" autocomplete="customer.city" id="city" required />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form_field">
                                <label for="zipcode">Zip code</label>
                                <input type="text" v-model="customer.zipCode" autocomplete="customer.zip" id="zipCode" minlength="5" maxlength="5" required />
                            </div>
                            <div class="form_field">
                                <label for="tel_num">Phone number</label>
                                <input type="tel" id="tel_num" v-model="customer.phoneNumber" autocomplete="customer.phoneNumber" minlength="9" maxlength="10" required />
                            </div>
                        </div>
                        <div class="cta" data-style="see-through" data-alignment="right" data-text-color="custom">
                            <div class="link_wrap">
                                <img loading="lazy" decoding="async" src="../assets/Images/icons/Norton_Icon.svg" alt="" />
                                <div class="form_btns">
                                    <input type="submit" value="Next ->" class="link_text next_btn" />
                                    <!--<span class="arrow-next"></span>-->
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </Transition>
            <transition name="slide-fade">
                <section v-show="step === 2">
                    <form class="form" method="post" action="#" @submit.prevent="next">
                        <div class="payement_choice">
                            <div class="card_header">
                                <div>
                                    <input type="radio" value="crdit_card" id="apple" />
                                    <label for="credit_card">Pay with credit card</label>
                                </div>
                                <div style="display: flex; justify-content: flex-end; gap: 0.5em">
                                    <img loading="lazy" decoding="async" src="../assets/Images/icons/Payment_visa.svg" alt="Visa" height="35" width="50" />
                                    <img loading="lazy" decoding="async" src="../assets/Images/icons/Payment_stripe.svg" alt="Stripe" height="35" width="50" />
                                    <img loading="lazy" decoding="async" src="../assets/Images/icons/Payment_mastercard.svg" alt="MasterCard" height="35" width="50" />
                                </div>
                            </div>
                            <div class="form-group form-group_second">
                                <div class="form_field first">
                                    <label for="card_name">Name on card</label>
                                    <input type="text" id="card_name" />
                                </div>
                                <div class="form_field second">
                                    <label for="expire_date">Expiry</label>
                                    <input type="month" id="expire_date" />
                                </div>
                            </div>
                            <div class="form-group form-group_second">
                                <div class="form_field first">
                                    <label for="card_number">Card number</label>
                                    <input type="text" id="card_number" />
                                </div>
                                <div class="form_field second">
                                    <label for="cvv">CVV</label>
                                    <input type="password" id="cvv" />
                                </div>
                            </div>
                        </div>
                        <div class="payement_choice other_payement">
                            <div>
                                <div>
                                    <input type="radio" name="apple" id="apple" />
                                    <label for="apple">Apple Pay</label>
                                </div>
                                <span> Unlimited users and unlimited individual data. </span>
                            </div>
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/Payment_Apple.svg" alt="Apple pay" width="70" height="50" />
                        </div>
                        <div class="payement_choice other_payement">
                            <div>
                                <div>
                                    <input type="radio" name="paypal" id="paypal" />
                                    <label for="paypal">Paypal</label>
                                </div>
                                <span> Unlimited users and unlimited individual data. </span>
                            </div>
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/Payment_paypal.svg" alt="Paypal" width="70" height="50" />
                        </div>
                        <div class="security">
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/Secure_icon.svg" alt="" height="35" width="35" />
                            <span>We protect your payment information using encryption to provide bank-level security.</span>
                        </div>

                        <div class="form-group">
                            <div class="link_wrap">
                                <img loading="lazy" decoding="async" src="../assets/Images/icons/Norton_Icon.svg" alt="" />
                                <div class="form_btns">
                                    <a class="link_text page_btns" href="#" @click.prevent="prev()">&#8592; Back</a>
                                    <input type="submit" value="Next &#8594;" class="link_text page_btns" style="background: #2196f3; color: #fff" />
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </transition>
            <transition name="slide-fade">
                <section v-show="step === 4">
                    <div class="success form">
                        <img loading="lazy" decoding="async" src="../assets/Images/icons/Check_icon.svg" alt="Thank you!" width="150" height="150" />
                        <p class="thanks">
                            <span class="thanks_head">Thanks a lot for putting up this order</span>
                            Your order has successfully been placed. You’ll fin all the details about your order below, and we’ll send you a shipping confirmation email as soon as your order ships. In
                            the meantime, you can share Mailto and earn store credit. <br />
                            Questions? Suggestions? insightful show thoughts?<br />
                            <a href="#">Shoot us an email.</a>
                        </p>
                        <div class="review">
                            <span> Payement Review</span>
                            <span>&#60;</span>
                            <span> details about payement</span>
                            <span> 1600 $</span>
                        </div>
                        <button>Home Page</button>
                    </div>
                </section>
            </transition>
            <transition name="slide-fade">
                <section v-show="step === 3">
                    <div class="success form">
                        <div class="checking">
                            <div class="font_500_13">
                                <span>Order review</span>
                                <button>></button>
                                <span class="font_400_12">{{ item_count }} items in chart</span>
                                <span class="font_400_12">$ 1300</span>
                            </div>
                            <div class="font_500_13">
                                <span>Coupons</span>
                                <button>+</button>
                            </div>
                            <div class="summary">
                                <div class="font_500_13">
                                    <span>Check out summary</span>
                                    <button>></button>
                                </div>
                                <div class="font_400_12">
                                    <span>Subtotal</span>
                                    <span>$1,300</span>
                                </div>
                                <div class="font_400_12">
                                    <span>discount</span>
                                    <span>$1,300</span>
                                </div>
                                <div class="font_400_12">
                                    <span>Extra fee</span>
                                    <span>$1,300</span>
                                </div>
                                <div class="font_400_12">
                                    <span>Shipping</span>
                                    <span>$1,300</span>
                                </div>
                                <div class="font_400_12">
                                    <span>Tax</span>
                                    <span>$1,300</span>
                                </div>
                                <div>
                                    <span>Total</span>
                                    <span>${{ total_amount }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="policy">
                            <input type="checkbox" id="policy" />
                            <label for="policy">Please check to acknowledge our <b> Privacy & Terms Policy</b></label>
                        </div>
                        <div>
                            <button>Buy ${{ total_amount }}</button>
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/Norton_Icon.svg" alt="" />
                        </div>
                    </div>
                </section>
            </transition>
        </section>
    </div>
</template>

<script>
/*import payementSwitch from "../components/payementSwitch.vue";
import payementChoice from "../components/payementChoice.vue";*/
export default {
    name: "PayementForm",
    components: {
        /*payementSwitch, payementChoice,*/
    },
    data() {
        return {
            steps: {},
            step: 1,
            item_count: 0,
            total_amount: 3000,
            customer: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                address: "",
                zipCode: "",
                state: "",
                city: "",
                email: "",
            },
        };
    },
    computed: {},
    methods: {
        prev() {
            this.step--;
        },
        next() {
            this.step++;
        },
        customerRegister: function () {
            this.hasSeenCongrats = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.register {
    display: block;
    color: #fff;
    width: 100%;
    padding-top: 10%;

    &-stepper {
        display: flex;
        justify-content: space-between;
        width: 65%;
        position: relative;
        margin: 2rem auto;

        .step {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 18%;
            gap: 1rem;

            /*-&-active {
				color:#2196f3;
				background-color: #2196f3;
			}

			&-done {
				background-color: #2196f3;
				color: #2196f3;
                
			}*/
            &-text {
                font-weight: 500;
                font-size: 15.0373px;
                line-height: 23px;
                color: #6b7a99;
                flex-grow: 1;
                &-active {
                    color: #2196f3;
                }
            }
            &-number {
                vertical-align: middle;
                display: flex;
                align-items: center;
                background: #6b7a99;
                // z-index: 2;
                border-radius: 50%;
                width: 1rem;
                height: 1rem;
                line-height: 20px;
                font-size: 16px;
                position: relative;
                border: 1rem solid #fff;

                &::after {
                    z-index: -1;
                    content: "";
                    height: 2.82px;
                    background: #6b7a99;
                    position: absolute;
                    margin: 0 2rem;
                    width: 10vw;
                    border-radius: 50px;
                }
                &-done {
                    background-color: #2196f3;
                    color: #2196f3;
                    display: flex;
                    align-items: center;

                    &::after {
                        z-index: -1;
                        content: "";
                        height: 2.82px;
                        background: #2196f3;
                        position: absolute;
                        margin: 0 2rem;
                        width: 10vw;
                        border-radius: 50px;
                    }
                }
                &-active {
                    color: #2196f3;
                    background-color: #2196f3;
                    box-shadow: 0 0 0 1rem #eff0f7;
                }
            }
        }
        > :last-child {
            .step-number::after {
                display: none;
            }
        }
    }

    section {
        background: #fff;
        padding: 3rem;
        box-shadow: 18.7411px 18.7411px 56.2234px 9.37056px rgba(2, 2, 70, 0.12);
        border-radius: 37.799px;
        margin-bottom: 10rem;

        .page_title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 21.7865px;
            line-height: 34px;
            color: #101828;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 2rem;

        label {
            text-align: left;
            padding-bottom: 0.5rem;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16.9451px;
            line-height: 24px;
            color: #344054 !important;
        }
        .payement_choice {
            background: #eff0f7;
            padding: 1em;
            border: 0.778215px solid #020246;
            border-radius: 6.22572px;
        }
        .card_header {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .other_payement {
            background: #fff;
            display: flex;

            div {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: start;
                align-items: baseline;
            }
            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 10.895px;
                line-height: 16px;
                color: #667085;
                padding: 0 2em;
            }
        }

        .security {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 11.6732px;
                line-height: 19px;
                color: #828282;
            }
        }

        &-group {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: center;
            gap: 50px;

            .form_field {
                display: flex;
                flex-flow: column;
                flex-grow: 1;
                width: 100%;
            }

            &.cta-step {
                color: #fff;
                justify-content: space-between;
            }
            &.password {
                margin-top: 2rem;
            }
        }
        &-group_second {
            margin: 1rem 0;
            .first {
                flex-grow: 2;
            }

            .second {
                flex-grow: 1;
                width: 9rem;
            }
        }

        .link_wrap {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 14em;

            .form_btns {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
            }
            a {
                justify-content: center;
                text-align: center;
                text-decoration: none;
                background: #ffffff;
                border: 0.775px solid #d0d5dd;
            }
            .page_btns {
                width: 40%;
                padding: 0.5rem;
                height: 100%;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 12.4px;
                line-height: 19px;
                color: #344054;
                border-radius: 6.2px;
                box-shadow: 6.2px 6.2px 18.6px rgba(2, 2, 70, 0.15);
            }

            .next_btn {
                background: #2196f3;
                border: 1.21036px solid #2196f3;
                box-shadow: 9.68291px 9.68291px 29.0487px rgba(2, 2, 70, 0.15);
                border-radius: 9.68291px;
                width: 100%;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 19.3658px;
                line-height: 29px;
                color: #ffffff;
            }

            .arrow-prev {
                position: relative;
                display: inline-block;
                transform: translate(0);
                transition: transform 0.3s ease-in-out;

                &::before {
                    content: "<";
                    position: absolute;
                    top: -17px;
                    left: -25px;
                }
            }

            .arrow-next {
                position: relative;
                display: inline-block;
                transform: translate(0);
                transition: transform 0.3s ease-in-out;
                color: #000;

                &::before {
                    content: ">";
                    position: absolute;
                    top: -10px;
                    left: -25px;
                }
            }

            &:hover .arrow-prev {
                transform: translate(-5px);
            }

            &:hover .arrow-next {
                transform: translate(5px);
            }
        }
        .checking {
            width: 100%;
            display: grid;
            gap: 0.5rem;

            div {
                width: 100%;
                border: 0.383033px solid #b2bcca;
                border-radius: 6.12853px;
                padding: 2%;
            }

            > :first-child {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                justify-items: baseline;

                > :nth-child(2),
                :nth-child(4) {
                    justify-self: flex-end;
                }
            }
            > :nth-child(2) {
                display: flex;
                justify-content: space-between;

                button {
                    border: 1px solid #000;
                    border-radius: 50%;
                    padding: revert;
                }
            }

            .summary {
                display: flex;
                flex-direction: column;
                align-items: center;

                div {
                    display: flex;
                    justify-content: space-between;
                    border: none;
                }
            }

            button {
                width: fit-content;
                background: transparent;
                color: #000;
                box-shadow: none;
                transform: rotate(90deg);
            }
        }
        .policy {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
        }
        .policy + div {
            width: 100%;
            display: flex;

            button {
                width: 100%;
            }
        }
    }
    .success {
        color: #000;
        font-family: "inter";
        text-align: center;
        align-items: center;
        padding: 5rem;
        padding-top: 0;

        .thanks {
            padding: 0 4rem;
            display: flex;
            flex-direction: column;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: rgba(0, 0, 0, 0.5);

            .thanks_head {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                color: #2196f3;
            }
        }

        .review {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: stretch;

            width: 90%;
            border: 0.382778px solid #b2bcca;
            border-radius: 18.3733px;
            font-family: "Inter";
            font-style: normal;
            color: #101828;
            padding: 0.5rem 1rem;
        }
        .review > :first-child {
            font-weight: 500;
            font-size: 13.78px;
            line-height: 21px;
            justify-self: baseline;
        }
        .review > :nth-child(3) {
            font-weight: 400;
            font-size: 12.2489px;
            line-height: 18px;
            justify-self: baseline;
        }
        .review > :nth-child(2) {
            transform: rotate(-90deg);
            font-weight: 500;
            font-size: 13.78px;
            line-height: 21px;
            justify-self: end;
        }
        .review > :nth-child(4) {
            font-weight: 400;
            font-size: 12.2489px;
            line-height: 18px;
            justify-self: end;
        }
        button {
            background: #2196f3;
            box-shadow: 6.2px 6.2px 18.6px rgba(2, 2, 70, 0.15);
            border-radius: 6.2px;
            width: 90%;
            font-weight: 500;
            font-size: 12.2571px;
            line-height: 18px;
            color: #ffffff;
            border: none;
            padding: 0.5rem 0;
        }
    }
    input[type="submit"],
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="date"],
    input[type="month"],
    input[type="password"] {
        -webkit-appearance: none;
        background: #ffffff;
        border: 1.21036px solid #d0d5dd;
        box-shadow: 0px 1.21036px 2.42073px rgba(16, 24, 40, 0.05);
        border-radius: 5px;
        height: 2.5rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 19.3658px;
        line-height: 29px;
        color: #000;
    }
    input[type="submit"] {
        cursor: pointer;
        position: relative;
        background: none;
        width: fit-content;

        &:hover,
        &:focus {
            box-shadow: unset;
            transform: none;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            border-radius: 50px;
            border: 1px solid #00c4b5;
            height: 25px;
            width: 25px;
            margin-top: -14px;
            pointer-events: none;
            transition: all 0.33s cubic-bezier(0.12, 0.75, 0.4, 1);
        }
    }
    &-btn input {
        color: #fff;
        font-size: 1.2rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        line-height: 1;
        width: fit-content;
        background: linear-gradient(145deg, #1b3c05, #173205);
        box-shadow:
            20px 20px 60px #142c04,
            -20px -20px 60px #1f4406;

        &:hover {
            background: linear-gradient(145deg, #173205, #1b3c05);
            box-shadow:
                20px 20px 60px #142c04,
                -20px -20px 60px #1f4406;
        }
    }
    // Transition SLIDE FADE
    .slide-fade-enter-active {
        transition: all 0.3s ease;
    }
    .slide-fade-leave-active {
        display: none;
        transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-fade-enter,
    .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
}
.header_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 41.1524px;
        line-height: 56px;
        text-align: center;
        color: #2196f3;
    }
    p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 21.7865px;
        line-height: 36px;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        width: 45rem;
    }
}
.select_box {
    border: 1.21036px solid #d0d5dd;
    width: 100%;
    display: inline-block;
    padding: 0 2%;
    height: 2.5rem;
    border-radius: 9.68291px;
    outline: none;
    background: transparent;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 19.3658px;
    line-height: 29px;
    color: #000;
}

.font_500_13 {
    font-weight: 500;
    font-size: 13.7892px;
    line-height: 21px;
    color: #101828;
}
.font_400_12 {
    font-weight: 400;
    font-size: 12.2571px;
    line-height: 18px;
}
</style>
